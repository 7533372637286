@import url("https://fonts.googleapis.com/css?family=Fira+Mono:700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Fira Mono", monospace;
}

html,
body {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: 100%;
  padding: 0 24px;
  max-width: 500px;
}

.player__progress-bar {
  width: 100%;
  height: 64px;
  border: 3px solid black;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
}

.progress-bar__time {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.player__button {
  flex: 0 0;
  color: white;
  background: black;
  min-height: 120px;
  width: 100%;
  line-height: 120px;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  outline: none;
  cursor: pointer;
}
